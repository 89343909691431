import './Gallery.css'
import LightGallery from 'lightgallery/react'
import images from './gallery-columns.json'
import { useCallback, useRef } from 'react'
import { InitDetail } from 'lightgallery/lg-events'
import lgZoom from 'lightgallery/plugins/zoom'
import useViewportWidth from './hooks/useViewportWidth'

const flattenedImages = images.flat().sort((a, b) => b.order - a.order)

const findIndex = (imageId: string) => flattenedImages.findIndex(i => i.id === imageId)

type Image = {
  id: string
  fileName: string
  title: string
  order: number
}

const ImageWithTitle = ({ image, onClick }: { image: Image; onClick: () => void }) => {
  return (
    <div className="image-container" onClick={onClick}>
      <img src={`/media/main-page/thumbnails/${image.fileName}`} />

      <div className="gallery-hover-block text-center">
        <h3 className="name">{image.title}</h3>
      </div>
    </div>
  )
}

const Column = ({
  images,
  showGallery,
}: {
  images: Image[]
  showGallery: (index: number) => () => void
}) => {
  return (
    <>
      {images
        .sort((a, b) => b.order - a.order)
        .map(image => (
          <ImageWithTitle key={image.id} image={image} onClick={showGallery(findIndex(image.id))} />
        ))}
    </>
  )
}

export default () => {
  const gallery = useRef<InitDetail>()
  const viewportWidth = useViewportWidth()

  const isMobile = viewportWidth < 768

  const onInit = useCallback((detail: InitDetail) => {
    if (detail) gallery.current = detail
  }, [])

  const showGallery = (index: number) => () => {
    gallery.current?.instance.openGallery(index)
  }

  const columnEls = isMobile ? (
    <Column images={flattenedImages} showGallery={showGallery} />
  ) : (
    images.map((column, index) => (
      <div key={index} className="column">
        <Column images={column} showGallery={showGallery} />
      </div>
    ))
  )

  return (
    <div className="main-gallery">
      {columnEls}
      <LightGallery
        onInit={onInit}
        dynamic
        dynamicEl={flattenedImages.map(i => ({ src: `/media/main-page/full/${i.fileName}` }))}
        plugins={[lgZoom]}
      />
    </div>
  )
}
